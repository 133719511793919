import { useParams } from 'react-router-dom';

import Player from '../components/Player/Player';

const CLOUD_URL = 'https://stream.tourbuilder.cloud';

export default function Clips() {
  const { tourId, videoId } = useParams();

  const subtitlesUrl = `${CLOUD_URL}/clips/${tourId}/${videoId}/subtitles.vtt`;
  const videoUrl = `${CLOUD_URL}/clips/${tourId}/${videoId}/master.m3u8`;

  return <Player subtitlesUrl={subtitlesUrl} videoUrl={videoUrl} />;
}
