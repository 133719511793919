import { useMemo } from 'react';
import ReactPlayer from 'react-player/lazy';

import styles from './Player.module.scss';

interface PlayerProps {
  videoUrl: string;
  subtitlesUrl?: string;
}

export default function Player({ videoUrl, subtitlesUrl }: PlayerProps) {
  const videoConfig = useMemo(
    () =>
      subtitlesUrl
        ? {
            file: {
              attributes: {
                crossOrigin: 'true',
              },
              tracks: [
                {
                  kind: 'subtitles',
                  src: subtitlesUrl,
                  srcLang: 'en',
                  default: true,
                  label: 'Subtitles',
                },
              ],
            },
          }
        : undefined,
    [subtitlesUrl],
  );

  return (
    <>
      {!videoUrl && 'Please provide videoUrl param'}
      {videoUrl && !ReactPlayer.canPlay(videoUrl) && 'Video not supported'}
      {videoUrl && ReactPlayer.canPlay(videoUrl) && (
        <ReactPlayer
          className={styles.reactPlayer}
          config={videoConfig}
          controls
          height="100%"
          url={videoUrl}
          width="100%"
        />
      )}
    </>
  );
}
