import { useRouteError } from 'react-router-dom';

export function ErrorBoundary() {
  const error = useRouteError();

  console.error(error);

  return (
    <div>
      {typeof error === 'object' &&
      error !== null &&
      'status' in error &&
      'statusText' in error
        ? `${error.status} ${error.statusText}`
        : 'Something went wrong. Please check the URL and try again.'}
    </div>
  );
}
