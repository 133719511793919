import Player from '../components/Player/Player';
import useURLParam, {
  SupportedURLParam,
} from '../hooks/useURLParam/useURLParam';

export default function Root() {
  const videoUrl = useURLParam(SupportedURLParam.VIDEO_URL);
  const subtitlesUrl = useURLParam(SupportedURLParam.SUBTITLES_URL);

  return <Player subtitlesUrl={subtitlesUrl} videoUrl={videoUrl} />;
}
