import { useParams } from 'react-router-dom';

import Player from '../components/Player/Player';

const CLOUD_URL = 'https://stream.tourbuilder.cloud';

export default function Video() {
  const { clientId, tourId, videoId } = useParams();

  const videoUrl = `${CLOUD_URL}/videos/${clientId}/${tourId}/${videoId}/master.m3u8`;

  return <Player videoUrl={videoUrl} />;
}
