import { useMemo } from 'react';

export enum SupportedURLParam {
  VIDEO_URL = 'videoUrl',
  SUBTITLES_URL = 'subtitlesUrl',
}

export default function useURLParam(name: SupportedURLParam) {
  return useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    const param = params.get(name);

    if (!param) return '';

    return param;
  }, [name]);
}
