import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import Clips from '../../routes/clips';
import Root from '../../routes/root';
import Video from '../../routes/video';

import styles from './App.module.scss';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/clips/:tourId/:videoId',
    element: <Clips />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/video/:clientId/:tourId/:videoId',
    element: <Video />,
    errorElement: <ErrorBoundary />,
  },
]);

export default function App() {
  return (
    <div className={styles.app}>
      <RouterProvider router={router} />
    </div>
  );
}
